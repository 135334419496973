<template>
  <div class="card">
    <div class="container-fluid mt-5">
      <div class="row">
        <div class="mr-4 h1">
          <router-link to="/ticketing"
            ><div><b-icon-arrow-left></b-icon-arrow-left></div
          ></router-link>
        </div>
        <div class="mb-10">
          <h2>Ticket History</h2>
        </div>
      </div>
      <DxDataGrid
        :ref="my - data - grid"
        :data-source="itemseditearly"
        key-expr="id"
        :show-borders="true"
        :word-wrap-enabled="false"
        :column-auto-width="false"
        :remote-operations="true"
        :column-hiding-enabled="true"
      >
        <DxSearchPanel :visible="false" />
        <DxExport :enabled="true" />
        <DxFilterRow :visible="false" />
        <DxColumn
          data-field="user.old_data"
          :calculate-cell-value="getTiketId"
          caption="Tiket ID"
        />
        <DxColumn
          data-field="user.old_data"
          :calculate-cell-value="getSiteId"
          caption="Site ID"
        />
        <DxColumn
          data-field="user.old_data"
          :calculate-cell-value="getsiteLocation"
          caption="Site Location"
        />
        <!-- <DxColumn
          data-field="user.status"
          :calculate-cell-value="getStatus"
          caption="Status"
        />
        <DxColumn
          data-field="user.status"
          :calculate-cell-value="getCreatedat"
          caption="Created At"
        /> -->
        <DxColumn
          data-field="user.status"
          :calculate-cell-value="getAlert"
          caption="Problem"
        />
        <DxColumn
          data-field="user.status"
          :calculate-cell-value="getUpdated"
          caption="Close Date"
        />
        <DxColumn
          data-field="user.status"
          :calculate-cell-value="getDesc"
          caption="Description"
        />
        <DxColumn
          data-field="operator"
          :calculate-cell-value="getEmail"
          caption="Email Saber"
        />
        <DxColumn
          data-field="operator"
          :calculate-cell-value="getSaber"
          caption="Saber"
        />
        <DxPaging :page-size="10" />
        <DxPager
          :visible="true"
          :show-page-size-selector="showPageSizeSelector"
          :allowed-page-sizes="pageSizes"
        />
      </DxDataGrid>
      <b-modal
        v-model="showModalEditadv"
        title="Detail Advanced"
        scrollable
        size="lg"
        id="edit-user-modal-adv"
      >
        <b-container fluid>
          <b-row class="mb-2">
            <b-col cols="3">Ticket ID</b-col>
            <b-col>
              {{ detaildataadv.ticket_id }}
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="3">Site ID</b-col>
            <b-col>
              {{ detaildataadv.site_id }}
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="3">Location Name</b-col>
            <b-col>
              {{ detaildataadv.site_location }}
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="3">Category</b-col>
            <b-col>
              {{ detaildataadv.category }}
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="3">Sub Category</b-col>
            <b-col>
              {{ detaildataadv.subcategory_id }}
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="3">Update Time</b-col>
            <b-col>
              {{ detaildataadv.updated_at }}
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="3">Operator</b-col>
            <b-col> Operator </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="3">Saber</b-col>
            <b-col> Saber </b-col>
          </b-row>
        </b-container>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              size="sm"
              class="float-right"
              @click="showModalEditadv = false"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxExport,
  DxSearchPanel,
  DxFilterRow,
} from "devextreme-vue/data-grid";
import { customers } from "../../mockjson";
import WebService from "../../core/services/api";
import moment from "moment";
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DxDataGrid,
    DxExport,
    DxColumn,
    DxSearchPanel,
    DxFilterRow,
  },
  data() {
    return {
      dataSource: customers,
      detaildataadv: "",
      searchQuery: null,
      editvisit: false,
      showModalEditadv: false,
      itemsadvanced: [],
      itemseditearly: [],
      infoModaladv: {
        id: "info-modal",
        title: "",
        content: ""
      }
    };
  },
  computed: {
    resultQuery() {
      if (this.searchQuery) {
        return this.items.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.fields.toLowerCase().includes(v));
        });
      } else {
        return this.items;
      }
    }
  },
  mounted() {
    this.getDataTableAdvanced();
  },
  methods: {
    info(item, index, button) {
      console.log(item, index, button);
      this.infoModal.title = `Detail`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    getDataTableAdvanced() {
       WebService.getAuditTiket()
        .then(res => {
          this.itemseditearly = res.data.data;
        })
        .catch(error => {
          console.log(error);
          this.itemseditearly = [];
        });
    },
    getId(data) {
      return JSON.parse(data.old_data).id;
    },
    getTiketId(data) {
      return JSON.parse(data.old_data).ticket_id;
    },
    getSiteId(data){
      return JSON.parse(data.old_data).site_id;
    },
    getsiteLocation(data){
      return JSON.parse(data.old_data).site_location;
    },
    getSaber(data) {
      return JSON.parse(data.old_data).saber;
    },
    getDesc(data){
      return JSON.parse(data.old_data).description;
    },
    getEmail(data) {
      return JSON.parse(data.old_data).email;
    },
    getAlert(data) {
      return JSON.parse(data.old_data).problem;
    },
    getUpdated(data) {
      return this.format_date(JSON.parse(data.old_data).updated_at);
    },
    getStatus(data) {
      return JSON.parse(data.new_data).status;
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    hapusvisit(index) {
      this.items.splice(index, 1);
    },
    format_date(value) {
      if (value) {
        return moment(String(value))
          .lang("id")
          .format("DD MMMM YYYY hh:mm");
      }
    },
    iconAdvanced(e) {
      this.showModalEditadv = true;
      this.detaildataadv = e.row.data;
    }
  }
};
</script>

<style></style>
